/*App.css*/

* 


{
	box-sizing: border-box;
}

input[type=date],
select,
textarea {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	resize: vertical;
}

label {
	padding: 12px 12px 12px 0;
	display: inline-block;
}

input[type=submit] {
	background-color: #047baa;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	float: right;
	margin-top: 2rem;
}

button {
	background-color: #047baa;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1rem;
	margin-right: 1rem;
}

button:hover,
[type=submit]:hover {
	background-color: #1183a0;
}

.container {
	width: 80%;
	margin: auto;
	border-radius: 5px;
	background-color: #f2f2f2;
	padding: 20px;
}

h1 {
	text-align: center;
	color: #0446aa;
}



.list-ul li{
	text-align: center;	
	display: inline-block;
	padding: 10px;

}


.col-25 {
	float: left;
	width: 25%;
	margin-top: 6px;
}

.col-75 {
	float: left;
	width: 75%;
	margin-top: 6px;
}

.row::after {
	content: "";
	display: table;
	clear: both;
}

#list {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

#list td,
#list th {
	border: 1px solid #ddd;
	padding: 8px;
}

#list tr:nth-child(even) {
	background-color: #f2f2f2;
}

#list tr:hover {
	background-color: #ddd;
}

#list th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #0446aa;
	color: white;
}

@media screen and (max-width: 600px) {

	.col-25,
	.col-75,
	input[type=submit] {
		width: 100%;
		margin-top: 0;
	}
}

#list {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #list th, #list td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #list th {
    background-color: #0446aa;
    color: white;
    text-align: left;
  }
  
  #list tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  #list tr:hover {
    background-color: #ddd;
  }
  
  #list td a {
    color: #0446aa;
    text-decoration: none;
  }
  
  #list td a:hover {
    text-decoration: underline;
  }

  .gapi__font-size {
	font-size: 10px; 
	font-family: Arial, sans-serif; 
  }

  .pagination button.active {
	background-color: #007bff; /* Highlight color */
	color: white; /* Text color for the highlighted button */
	font-weight: bold; /* Optional: make text bold */
  }
  
  .pagination button.disabled {
	background-color: #e0e0e0; /* Disabled button color */
	color: #b0b0b0; /* Disabled text color */
	cursor: not-allowed; /* Cursor style for disabled state */
  }
  
  .pagination button {
	margin: 0 2px; /* Spacing between buttons */
	padding: 5px 10px; /* Padding inside buttons */
	border: 1px solid #ccc; /* Button border */
	border-radius: 4px; /* Optional: rounded corners */
	background-color: #f8f9fa; /* Default button background */
	color: #333; /* Default text color */
	cursor: pointer; /* Cursor style for clickable buttons */
  }
  
  .pagination button:hover:not(.disabled) {
	background-color: #ddd; /* Hover color for non-disabled buttons */
  }
  